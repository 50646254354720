import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Blog from '../views/Blog.vue'
// import Login from '../views/User/Login.vue'
// import Register from '../views/User/Register.vue'
// import Profile from '../views/User/Profile.vue'
// import ForgotPassword from '../views/User/ForgotPassword.vue'
// import Admin from '../views/User/Admin.vue'
// import CreatePost from '../views/Blog/CreatePost.vue'
// import BlogPreview from '../views/Blog/BlogPreview.vue'
// import ViewBlog from '../views/Blog/ViewBlog.vue'
// import EditBlog from '../views/Blog/EditBlog.vue'
// import DisasterRecovery from '../views/DisasterRecovery/DisasterRecovery.vue'
// import Cybersecurity from '../views/Cybersecurity/Cybersecurity.vue'
// import ITServices from '../views/ITServices/ITServices.vue'
// import HardwareSoftware from '../views/HardwareSoftware/HardwareSoftware.vue'

const Home = () => import('../views/Home.vue')
const Blog = () => import('../views/Blog.vue')
const Login = () => import('../views/User/Login.vue')
const Register = () => import('../views/User/Register.vue')
const Profile = () => import('../views/User/Profile.vue')
const ForgotPassword = () => import('../views/User/ForgotPassword.vue')
const Admin = () => import('../views/User/Admin.vue')
const CreatePost = () => import('../views/Blog/CreatePost.vue')
const BlogPreview = () => import('../views/Blog/BlogPreview.vue')
const ViewBlog = () => import('../views/Blog/ViewBlog.vue')
const EditBlog = () => import('../views/Blog/EditBlog.vue')
const DisasterRecovery = () => import('../views/DisasterRecovery/DisasterRecovery.vue')
const Arcserve = () => import('../views/DisasterRecovery/Arcserve.vue')
const Cybersecurity = () => import('../views/Cybersecurity/Cybersecurity.vue')
const Vicarius = () => import('../views/Cybersecurity/Vicarius.vue')
const CyberObserver = () => import('../views/Cybersecurity/CyberObserver.vue')
const Splunk = () => import('../views/Cybersecurity/Splunk.vue')
const ITServices = () => import('../views/ITServices/ITServices.vue')
const Vmware = () => import('../views/ITServices/Vmware.vue')
const HardwareSoftware = () => import('../views/HardwareSoftware/HardwareSoftware.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'HomeEs',
    component: Home
  },
  {
    path: '/',
    name: 'HomeEn',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      title: 'Admin'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile'
    }
  },
  {
    path: '/create-post',
    name: 'CreatePost',
    component: CreatePost,
    meta: {
      title: 'Create Post'
    }
  },
  {
    path: '/post-preview',
    name: 'BlogPreview',
    component: BlogPreview,
    meta: {
      title: 'Preview Blog Post'
    }
  },
  {
    path: '/view-blog/:blogid',
    name: 'ViewBlog',
    component: ViewBlog,
    meta: {
      title: 'View Blog Post'
    }
  },
  {
    path: '/edit-blog/:blogid',
    name: 'EditBlog',
    component: EditBlog,
    meta: {
      title: 'Edit Blog Post'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/disasterrecovery',
    name: 'Disaster Recovery',
    component: DisasterRecovery
  },
  {
    path: '/disasterrecovery',
    name: 'Disaster RecoveryEn',
    component: DisasterRecovery
  },
  {
    path: '/arcservesolutions',
    name: 'Arcserve',
    component: Arcserve
  },
  {
    path: '/hardwaresoftware',
    name: 'Hardware Software',
    component: HardwareSoftware
  },
  {
    path: '/cybersecurity',
    name: 'Cybersecurity',
    component: Cybersecurity
  },
  {
    path: '/applicationvulnerabilitymanagement',
    name: 'Vicarius',
    component: Vicarius
  },
  {
    path: '/compliancemonitoring',
    name: 'CyberObserver',
    component: CyberObserver
  },
  {
    path: '/eventmonitoring',
    name: 'Splunk',
    component: Splunk
  },
  {
    path: '/vmware',
    name: 'Vmware',
    component: Vmware
  },
  {
    path: '/itservices',
    name: 'IT Services',
    component: ITServices
  },
  {
    path: '/arcservesolutions',
    name: 'ArcserveEn',
    component: Arcserve
  },
  {
    path: '/hardwaresoftware',
    name: 'Hardware SoftwareEn',
    component: HardwareSoftware
  },
  {
    path: '/cybersecurity',
    name: 'CybersecurityEn',
    component: Cybersecurity
  },
  {
    path: '/applicationvulnerabilitymanagement',
    name: 'VicariusEn',
    component: Vicarius
  },
  {
    path: '/compliancemonitoring',
    name: 'CyberObserverEn',
    component: CyberObserver
  },
  {
    path: '/eventmonitoring',
    name: 'SplunkEn',
    component: Splunk
  },
  {
    path: '/itservices',
    name: 'IT ServicesEn',
    component: ITServices
  },
  {
    path: '/vmwaresolutions',
    name: 'VmwareEn',
    component: Vmware
  },
  {
    path: '/blog',
    name: 'BlogEn',
    component: Blog
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }

})

export default router
