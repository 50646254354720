<template>
<div>
  <footer id="footer" class="footer"  v-if="!(language.indexOf('es') === -1)">
    <ContactInHome :language =  "'es'"/>
    <div class="footer-newsletter" v-if="1==2">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <h4>Nuestro Newsletter</h4>
            <p>Suscribite a nuestro newsletter para recibir noticias, promociones y avisos de seguridad</p>
          </div>
          <div class="col-lg-6">
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Suscribir">
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top" style="height: 363px;">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info" style="text-align: left;">
            <router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center">
              <img src="assets/img/favicon.webp" style ="height: 40px; width: 40px;">
              <span>Tecnosophie</span>
            </router-link>
            <p>Avanzada en soluciones</p>
            <div class="social-links mt-3">
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Tecnosophie" class="twitter"><i class="bi bi-twitter"></i></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/tecnosophie" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Links útiles</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#about'">Sobre Nosotros</router-link></li>
              <li><i class="bi bi-chevron-right"></i> <router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#main'">Soluciones</router-link></li>

            </ul>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Nuestras Soluciones</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'Disaster Recovery', params: { userId: 123 }}" >Disaster Recovery</router-link></li>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" >Cybersecurity</router-link></li>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'IT Services', params: { userId: 123 }}" >IT Services</router-link></li>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'Hardware Software', params: { userId: 123 }}" >Hardware & Software</router-link></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>
              <strong>Email:</strong> info@tecnosophie.com<br>
            </p>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright 2021. All Rights Reserved
      </div>
    </div>
  </footer>
    <footer id="footer" class="footer" v-if="(language.indexOf('es') === -1)">
    <ContactInHome :language =  "'en'"/>
    <div class="footer-newsletter" v-if="1==2">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <h4>Our Newsletter</h4>
            <p>Suscribe</p>
          </div>
          <div class="col-lg-6">
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Suscribir">
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info" style="text-align: left;">
            <router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center">
              <img src="assets/img/favicon.webp" style ="height: 40px; width: 40px;">
              <span>Tecnosophie</span>
            </router-link>
            <p> </p>
            <div class="social-links mt-3">
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Tecnosophie" class="twitter"><i class="bi bi-twitter"></i></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/tecnosophie" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Some Links</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#about'">About Us</router-link></li>
              <li><i class="bi bi-chevron-right"></i> <router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#main'">Solutions</router-link></li>

            </ul>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Our Solutions</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'Disaster RecoveryEn', params: { userId: 123 }}" >Disaster Recovery</router-link></li>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'CybersecurityEn', params: { userId: 123 }}" >Cybersecurity</router-link></li>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'IT ServicesEn', params: { userId: 123 }}" >IT Services</router-link></li>
              <li><i class="bi bi-chevron-right"></i><router-link :to="{ name: 'Hardware SoftwareEn', params: { userId: 123 }}" >Hardware & Software</router-link></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>
              <strong>Email:</strong> info@tecnosophie.com<br>
            </p>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright 2021. All Rights Reserved
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import ContactInHome from './Home/ContactInHome.vue'
export default {
  components: {
    ContactInHome
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: {
    msg: String,
    language: String
  }
}
</script>

<style>

</style>
