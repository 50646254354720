<template>
  <!-- ======= Header ======= -->
  <div>
    <div v-if="(!(language.indexOf('es') === -1))">
  <header id="header" class="header fixed-top" style="position: absolute">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
      <router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center">
        <img src="assets/img/logogrande.webp" style="max-height:55px; height: 55px; width: 100%;" data-aos="zoom-in">
      </router-link>
      <nav id="navbar" class="navbar" v-if="!mobileView" data-aos="zoom-in">
        <ul>
          <li><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center">Home</router-link></li>
          <li><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#about'">Sobre Nosotros</router-link></li>
          <li class="dropdown">
            <router-link :to="{ name: 'Home', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#main'">
              Soluciones <i class="bi bi-chevron-down"></i></router-link>
            <ul>
              <li><router-link :to="{ name: 'Disaster Recovery', params: { userId: 123 }}" >Disaster Recovery</router-link></li>
              <li><router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" >Cybersecurity</router-link></li>
              <li><router-link :to="{ name: 'IT Services', params: { userId: 123 }}" >IT Services</router-link></li>
              <li><router-link :to="{ name: 'Hardware Software', params: { userId: 123 }}" >Hardware & Software</router-link></li>
            </ul>
          </li>
          <li class="dropdown"><a href="#"><span>Recursos</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><router-link :to="{ name: 'Blog', params: { }}">Blog</router-link></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://tecnosophie.freshdesk.com/support/home">Soporte</a></li>
            </ul>
          </li>
          <li>
            <router-link :to="{ name: 'Home', params: { userId: 123 }}" class="getstarted scrollto" v-scroll-to="'#contact'">
            Contacto</router-link></li>
            <li><router-link :to="{ name: 'HomeEn', params: { languaje: 'en' }}" class="logo d-flex align-items-center" @click.native="reloadpage()" style="display: none!important;">ENG</router-link></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

      <nav id="navbar-mobile" class="navbar navbar-mobile" v-if="showNav" data-aos="zoom-in">
        <ul>
          <li><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="a" v-scroll-to="'#'">Home</router-link></li>
          <li><router-link :to="{ name: 'Home', params: { userId: 123 }}" class="a" v-scroll-to="'#about'">Sobre Nosotros</router-link></li>
          <li class="dropdown">
            <a href="#"><span>Soluciones</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="dropdown-active">
              <li><router-link :to="{ name: 'Disaster Recovery', params: { userId: 123 }}" >Disaster Recovery</router-link></li>
              <li><router-link :to="{ name: 'Cybersecurity', params: { userId: 123 }}" >Cybersecurity</router-link></li>
              <li><router-link :to="{ name: 'IT Services', params: { userId: 123 }}" >IT Services</router-link></li>
              <li><router-link :to="{ name: 'Hardware Software', params: { userId: 123 }}" >Hardware & Software</router-link></li>
            </ul>
          </li>
          <li class="dropdown"><a href="#"><span>Recursos</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="dropdown-active">
              <li><router-link :to="{ name: 'Blog', params: { }}">Blog</router-link></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://tecnosophie.freshdesk.com/support/home">Soporte</a></li>
            </ul>
          </li>
          <li>
            <router-link :to="{ name: 'Home', params: { userId: 123 }}" class="getstarted scrollto" v-scroll-to="'#contact'">
            Contacto</router-link></li>
          <li><router-link :to="{ name: 'HomeEn', params: { languaje: 'en' }}" class="logo d-flex align-items-center" @click.native="reloadpage()" style="display: none!important;" >ENG</router-link></li>
        </ul>
        <i class="bi bi-list bi mobile-nav-toggle bi-x" @click="showNav = !showNav"></i>
      </nav><!-- .navbar -->
      <i class="bi bi-list mobile-nav-toggle" @click="showNav = !showNav"> </i>
    </div>
  </header><!-- End Header -->
  </div>
  <!-- En ingles -->
  <div v-if="((language.indexOf('es') === -1))">
  <header id="header" class="header fixed-top" style="position: absolute">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
      <router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center">
        <img src="assets/img/logogrande.webp" style="max-height:55px; height: 55px; width: 100%;" data-aos="zoom-in">
      </router-link>
      <nav id="navbar" class="navbar" v-if="!mobileView" data-aos="zoom-in">
        <ul>
          <li><router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center">Home</router-link></li>
          <li><router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#about'">About Us</router-link></li>
          <li class="dropdown">
            <router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="logo d-flex align-items-center" v-scroll-to="'#main'">
              Solutions <i class="bi bi-chevron-down"></i></router-link>
            <ul>
              <li><router-link :to="{ name: 'Disaster RecoveryEn', params: { userId: 123 }}" >Disaster Recovery</router-link></li>
              <li><router-link :to="{ name: 'CybersecurityEn', params: { userId: 123 }}" >Cybersecurity</router-link></li>
              <li><router-link :to="{ name: 'IT ServicesEn', params: { userId: 123 }}" >IT Services</router-link></li>
              <li><router-link :to="{ name: 'Hardware SoftwareEn', params: { userId: 123 }}" >Hardware & Software</router-link></li>
            </ul>
          </li>
          <li class="dropdown"><a href="#"><span>Resources</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><router-link :to="{ name: 'BlogEn', params: { }}">Blog</router-link></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://tecnosophie.freshdesk.com/support/home">Support</a></li>
            </ul>
          </li>
          <li>
            <router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="getstarted scrollto" v-scroll-to="'#contact'">
            Contact Us</router-link></li>
            <li><router-link :to="{ name: 'HomeEs', force: true, params: { languaje: 'es' }}" class="logo d-flex align-items-center" @click.native="reloadpage()" style="display: none!important;">ESP</router-link></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

      <nav id="navbar-mobile" class="navbar navbar-mobile" v-if="showNav" data-aos="zoom-in">
        <ul>
          <li><router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="a" v-scroll-to="'#'">Home</router-link></li>
          <li><router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="a" v-scroll-to="'#about'">About us</router-link></li>
          <li class="dropdown">
            <a href="#"><span>Solutions</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="dropdown-active">
              <li><router-link :to="{ name: 'Disaster RecoveryEn', params: { userId: 123 }}" >Disaster Recovery</router-link></li>
              <li><router-link :to="{ name: 'CybersecurityEn', params: { userId: 123 }}" >Cybersecurity</router-link></li>
              <li><router-link :to="{ name: 'IT ServicesEn', params: { userId: 123 }}" >IT Services</router-link></li>
              <li><router-link :to="{ name: 'Hardware SoftwareEn', params: { userId: 123 }}" >Hardware & Software</router-link></li>
            </ul>
          </li>
          <li class="dropdown"><a href="#"><span>Resources</span> <i class="bi bi-chevron-down"></i></a>
            <ul class="dropdown-active">
              <li><router-link :to="{ name: 'BlogEn', params: { }}">Blog</router-link></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://tecnosophie.freshdesk.com/support/home">Support</a></li>
            </ul>
          </li>
          <li>
            <router-link :to="{ name: 'HomeEn', params: { userId: 123 }}" class="getstarted scrollto" v-scroll-to="'#contact'">
            Contact</router-link></li>
            <li><router-link :to="{ name: 'HomeEs', params: { languaje: 'es' }}" class="logo d-flex align-items-center" @click.native="reloadpage()" style="display: none!important;">ESP</router-link></li>
        </ul>
        <i class="bi bi-list bi mobile-nav-toggle bi-x" @click="showNav = !showNav"></i>
      </nav><!-- .navbar -->
      <i class="bi bi-list mobile-nav-toggle" @click="showNav = !showNav"> </i>
    </div>
  </header><!-- End Header -->
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Header',
  data: () => {
    return {
      mobileView: true,
      showNav: false
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    },
    reloadpage () {
      window.location.reload()
    }
  },
  components: {
  },
  props: {
    msg: String,
    language: String
  },
  created () {
    console.log('Esto llego al Header')
    console.log(this.language)
    this.handleView()
    window.addEventListener('resize', this.handleView)
  }
}
</script>

<style>

</style>
