<template>
  <div id="app" >
    <Header :language = "lenguajeactual" />
    <router-view ></router-view>
    <Footer  :language = "lenguajeactual" />
      <go-top style="height:50px; width:50px;background-color:#e36d2682"></go-top>
  </div>
</template>

<script>
import Header from './views/Header.vue'
import Footer from './views/Footer.vue'
import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
// import GoTop from '@inotom/vue-go-top'

const GoTop = () => import('@inotom/vue-go-top')

export default {
  name: 'App',
  components: {
    Header, Footer, GoTop
  },
  data () {
    return {
      navigation: null,
      lenguajeactual: this.getlanguage()
    }
  },
  created () {
    console.log(navigator.language)
  },
  mounted () {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit('updateUser', user)
      if (user) {
        this.$store.dispatch('getCurrentUser', user)
      }
    })
    this.$store.dispatch('getPost')
  },
  methods: {
    getlanguage () {
      console.log(window.location.hash)
      if (!(window.location.hash.indexOf('es') === -1)) {
        console.log('Dice Es AsyncHome')
        return 'es'
      }
      if (!(window.location.hash.indexOf('en/') === -1)) {
        console.log('Dice En AsyncHome')
        return 'en'
      } else {
        console.log('Lenguaje Automático')
        return navigator.language
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
