import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: 'AIzaSyDYcgOc1FvCwAEhkTM9y8mzTffRUUzojQY',
  authDomain: 'tecnoblog-38ad6.firebaseapp.com',
  projectId: 'tecnoblog-38ad6',
  storageBucket: 'tecnoblog-38ad6.appspot.com',
  messagingSenderId: '563589238845',
  appId: '1:563589238845:web:047de375a68d905ce29a54',
  measurementId: 'G-CTYE9W4YSL'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const timestamp = firebase.firestore.FieldValue.serverTimeStap

export { timestamp }
export default firebaseApp.firestore()
