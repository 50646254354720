<template>
<div class="embed-responsive embed-responsive-16by9">
    <iframe src="https://booking.setmore.com/scheduleappointment/7c0dd884-0b7c-4d20-97e4-eae40c58a6e9/services/fba41fc6-a981-4b93-a0ed-4ebdf2e87dea" width="100%" height="1700px"></iframe>
  </div>
</template>

<script>
export default {
  name: 'MyIframe',
  data: () => ({
    myIframe: null
  }),
  methods: {
    onLoad (frame) {
      this.myIframe = frame.contentWindow
    }
  }
}
</script>
