<template>
<div>
     <section id="contact" class="contact" style="padding-bottom:10px" data-app v-if="!(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Queremos acompañarte</h2>
          <p>Contactanos</p>
        </header>
        <div class="row gy-4">
            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Llamanos</h3>
                  <p>+54 11 5711-5094</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>Envianos un correos</h3>
                  <p><a href="mailto:info@tecnosophie.com">info@tecnosophie.com</a><br><a href="mailto:soporte@tecnosophie.com">soporte@tecnosophie.com</a></p>
                </div>
              </div>
              <div class="col-md-6" >
                <div class="info-box" >
                  <i class="bi bi-clock"></i>
                  <br>
                  <br>
                <a  v-if="mobileView" rel="noopener noreferrer" href="https://booking.setmore.com/scheduleappointment/7c0dd884-0b7c-4d20-97e4-eae40c58a6e9/services/fba41fc6-a981-4b93-a0ed-4ebdf2e87dea" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Programa una Reunion</span>
                </a>
                </div>
                 <!-- Desde acá -->

                      <MeetDialog  v-if="!mobileView" :language =  "'es'"/>

                 <!-- Hasta acá -->
              </div>
              <div class="col-md-6">
                <div class="info-box">
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Tecnosophie" class="twitter"><i class="bi bi-twitter"></i></a>&nbsp;&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/tecnosophie" class="linkedin"><i class="bi bi-linkedin"></i></a>
                  <h3>Buscanos en Redes Sociales</h3>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section id="contact" class="contact" style="padding-bottom:10px" data-app v-if="(language.indexOf('es') === -1)" >
      <div class="container" data-aos="fade-up">
        <header class="section-header">
          <h2>Questions?</h2>
          <p>Contact us</p>
        </header>
        <div class="row gy-4">
            <div class="row gy-4">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-telephone"></i>
                  <h3>Call us</h3>
                  <p>+54 11 5711-5094</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bi bi-envelope"></i>
                  <h3>Send us an Email</h3>
                  <p><a href="mailto:info@tecnosophie.com">info@tecnosophie.com</a><br><a href="mailto:soporte@tecnosophie.com">soporte@tecnosophie.com</a></p>
                </div>
              </div>
              <div class="col-md-6" >
                <div class="info-box" >
                  <i class="bi bi-clock"></i>
                  <br>
                  <br>
                <a  v-if="mobileView" rel="noopener noreferrer" href="https://booking.setmore.com/scheduleappointment/7c0dd884-0b7c-4d20-97e4-eae40c58a6e9/services/fba41fc6-a981-4b93-a0ed-4ebdf2e87dea" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Schedule a Meeting</span>
                </a>
                </div>
                 <!-- Desde acá -->

                      <MeetDialog  v-if="!mobileView" :language =  "'en'" />

                 <!-- Hasta acá -->
              </div>
              <div class="col-md-6">
                <div class="info-box">
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Tecnosophie" class="twitter"><i class="bi bi-twitter"></i></a>&nbsp;&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/tecnosophie" class="linkedin"><i class="bi bi-linkedin"></i></a>
                  <h3>Social</h3>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import MeetDialog from '../../components/MeetDialog.vue'
// import emailjs from 'emailjs-com'
// import VueRecaptcha from 'vue-recaptcha'

// const VueRecaptcha = () => import('vue-recaptcha')

export default {
  name: 'About',
  data: () => {
    return {
      mobileView: true,
      showSent: false,
      recaptcha: null,
      cargacaptcha: false
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    },
    sendEmail () {
      // emailjs.sendForm('service_mbzurls', 'template_us55x3b', this.$refs.form, 'user_iTQEhpJ2k5tMF59hzD6e1')
      //   .then((result) => {
      //     this.showSent = true
      //   }, (error) => {
      //     console.log('FAILED...', error.text)
      //   })
    },
    mxVerify (response) {
      this.recaptcha = response
    },
    mxSubmit () {
      if (this.recaptcha) {
        this.sendEmail()
      } else {
        this.formInv = true
      }
    }
  },
  created () {
    console.log('Esto llego a Contact')
    console.log(this.language)
    this.handleView()
    window.addEventListener('resize', this.handleView)
    this.cargacaptcha = false
    this.cargacaptcha = setTimeout(function () {
      console.log(this.cargacaptcha)
      return true
    }, 10000)
  },
  mounted () {
  },
  components: {
    MeetDialog // ,VueRecaptcha
  },
  props: {
    msg: String,
    language: String
  }
}

</script>
