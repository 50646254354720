import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import 'vuetify/dist/vuetify.css'
import store from './store'
// import firebase from 'firebase/compat/app'

// const firebase = () => import('firebase/compat/app')

Vue.use(VueScrollTo)
Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: { id: 'UA-170311030-1' },
  appName: 'Tecno Site',
  pageTrackerScreenviewEnabled: true
}, router, VueRouter)

let app
// firebase.auth().onAuthStateChanged(() => {
//   if (!app) {
//     new Vue({
//       router,
//       store,
//       render: h => h(App)
//     }).$mount('#app')
//   }
// })

if (!app) {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
